import "./publications.css";

import Grid from "@mui/material/Grid";

import PublicationCard from "../../components/PublicationCard/PublicationCard";

const Publications = () => {
  return (
    <div className="publications">
      <h1 className="pub__header">Publications</h1>
      <Grid
        style={{
          maxHeight: "100vh",
          overflowY: "auto",
        }}
      >
        <h2 className="pub__year">2024</h2>
        <Grid item xs={12} padding={"10px"}>
          <PublicationCard
            title={
              "Forecasting Electric Vehicle Charging Station Occupancy: Smarter Mobility Data Challenge"
            }
            authors={
              "Y. Amara-Ouali, Y. Goude, N. Doumèche, P. Veyret, A. Thomas, D. Hebenstreit, T. Wedenig, A. Satouf, A. Jan, Y. Deleuze, P. Berhaut, S. Treguer"
            }
            journal={"Journal of Data-centric Machine Learning Research, Volume 1 2024"}
            pathPaper={
              "https://data.mlr.press/assets/pdf/v01-16.pdf"
            }
            pathScholar={
              "https://scholar.google.com/citations?view_op=view_citation&hl=fr&user=SHeUdQwAAAAJ&citation_for_view=SHeUdQwAAAAJ:u-x6o8ySG0sC"
            }
            pathImage={
              "https://data.mlr.press/assets/images/logo-dmlr.svg"
            }
          />
          <PublicationCard
            title={
              "Labeling Job Type and Technology for Large Operational Datasets"
            }
            authors={
              "S. Hassig Fonseca, A. Jan, K. Prokopetc, E. Ugarte, N. Vodnikov, P. Spesivtsev, P. Ramondenc, L. Venkataramanan"
            }
            journal={"SPE Western Regional Meeting, April 2024"}
            pathPaper={
              "https://onepetro.org/SPEWRM/proceedings-abstract/24WRM/1-24WRM/544000"
            }
            pathScholar={
              "https://scholar.google.com/citations?view_op=view_citation&hl=fr&user=SHeUdQwAAAAJ&citation_for_view=SHeUdQwAAAAJ:9yKSN-GCB0IC"
            }
            pathImage={
              "https://www.spe.org/static/www/img/spe_vert_white.svg"
            }
          />
        </Grid>
        <h2 className="pub__year">2023</h2>
        <Grid item xs={12} padding={"10px"}>
          <PublicationCard
            title={
              "An Explainable Artificial Intelligence Model For Top-of-Cement Identification"
            }
            authors={"T. Hou, A. Jan, Z. Snovida, M. Blyth"}
            journal={
              "SPE Annual Technical Conference and Exhibition, October 2023"
            }
            pathPaper={
              "https://onepetro.org/SPEATCE/proceedings-abstract/23ATCE/2-23ATCE/535476"
            }
            pathScholar={
              "https://scholar.google.com/citations?view_op=view_citation&hl=fr&user=SHeUdQwAAAAJ&citation_for_view=SHeUdQwAAAAJ:d1gkVwhDpl0C"
            }
            pathImage={
              "https://cdn.asp.events/CLIENT_SPE__29FE44FF_5056_B733_49EC4D60A02A6A7D/sites/atce-2023/media/logos/spe-atce-white.png"
            }
          />
        </Grid>
        <h2 className="pub__year">2022</h2>
        <Grid item xs={12} padding={"10px"} margin={"auto auto 300px auto"}>
          <PublicationCard
            title={
              "Multitasking Physics-Informed Neural Network for Drillstring Washout Detection"
            }
            authors={"A. Jan, F. Mahfoudh, G. Draškovic, C. Jeong, Y. Yu"}
            journal={"83rd EAGE Annual Conference & Exhibition, June 2022"}
            pathPaper={"https://doi.org/10.3997/2214-4609.202210607"}
            pathScholar={
              "https://scholar.google.com/citations?view_op=view_citation&hl=fr&user=SHeUdQwAAAAJ&citation_for_view=SHeUdQwAAAAJ:u5HHmVD_uO8C"
            }
            pathImage={
              "https://az659834.vo.msecnd.net/eventsairwesteuprod/production-eage-public/5161577208e1451fa450020fcf5c70db"
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Publications;
